import { NON_EURO_COUNTRIES } from "./constants";
import {
  Plan,
  PlanInfo,
  PlanInfoOrg,
  TPlanCode,
  Subscription,
  ICurrencyCodeResponse,
  TCurrencyKey,
  AccountType,
  Tier,
} from "models/subscription";
import { ReqProps } from "models/reqProps";
import { SegmentEventType } from "hooks/analyticsHook";
import { Coupon } from "models/coupon";

export const getCurrencyRegion = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TCurrencyKey => {
  if (!!countryCode) {
    if (countryCode === "GB") {
      return "GBP";
    }
    if (continentCode === "EU" && !NON_EURO_COUNTRIES.includes(countryCode)) {
      return "EUR";
    }
    if (countryCode === "CA") {
      return "CAD";
    }
    if (countryCode === "AU") {
      return "AUD";
    }
    if (countryCode === "BR") {
      return "BRL";
    }
  }
  return "USD";
};

export const getDefaultMonthlyPlanCode = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TPlanCode => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  switch (currencyRegion) {
    case "GBP":
      return "individual-monthly-gbp";
    case "EUR":
      return "individual-monthly-eur";
    case "AUD":
      return "individual-monthly-aud";
    case "CAD":
      return "individual-monthly-cad";
    case "BRL":
      return "individual-monthly-brl";
    default:
      return "individual-monthly-usd";
  }
};

export const getDefaultSixMonthPlanCode = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TPlanCode => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  switch (currencyRegion) {
    case "GBP":
      return "individual-sixMonth-gbp";
    case "EUR":
      return "individual-sixMonth-eur";
    case "AUD":
      return "individual-sixMonth-aud";
    case "CAD":
      return "individual-sixMonth-cad";
    case "BRL":
      return "individual-sixMonth-brl";
    default:
      return "individual-sixMonth-usd";
  }
};

export const getDefaultYearlyPlanCode = (
  countryCode: string | undefined,
  continentCode: string | undefined
): TPlanCode => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  switch (currencyRegion) {
    case "GBP":
      return "individual-yearly-gbp";
    case "EUR":
      return "individual-yearly-eur";
    case "AUD":
      return "individual-yearly-aud";
    case "BRL":
      return "individual-yearly-brl";
    case "CAD":
      return "individual-yearly-cad";
    default:
      return "individual-yearly-usd";
  }
};

export const isMonthlyCode = (planCode: TPlanCode): boolean => {
  return [
    "individual-monthly-aud",
    "individual-monthly-eur",
    "individual-monthly-gbp",
    "individual-monthly-cad",
    "individual-monthly-brl",
    "individual-monthly-usd",
  ].includes(planCode);
};

export const is6MonthlyCode = (planCode: TPlanCode): boolean => {
  return [
    "individual-sixMonth-aud",
    "individual-sixMonth-eur",
    "individual-sixMonth-gbp",
    "individual-sixMonth-cad",
    "individual-sixMonth-brl",
    "individual-sixMonth-usd",
  ].includes(planCode);
};

export const isYearlyPlanCode = (planCode: TPlanCode): boolean => {
  return [
    "individual-yearly-aud",
    "individual-yearly-eur",
    "individual-yearly-gbp",
    "individual-yearly-cad",
    "individual-yearly-brl",
    "individual-yearly-usd",
  ].includes(planCode);
};

/** Map TCurrencyKey to currency symbol */
export const currencyByCode = (
  currencyCode: TCurrencyKey
): ICurrencyCodeResponse => {
  switch (currencyCode) {
    case "GBP":
      return {
        currency: "£",
        currencyKey: "GBP",
      };
    case "EUR":
      return {
        currency: "€",
        currencyKey: "EUR",
      };
    case "AUD":
      return {
        currency: "A$",
        currencyKey: "AUD",
      };
    case "CAD":
      return {
        currency: "CA$",
        currencyKey: "CAD",
      };
    case "BRL":
      return {
        currency: "R$",
        currencyKey: "BRL",
      };
    default:
      return {
        currency: "$",
        currencyKey: "USD",
      };
  }
};

export const getCurrencyCodes = (
  countryCode: string | undefined,
  continentCode: string | undefined
): ICurrencyCodeResponse => {
  const currencyRegion = getCurrencyRegion(countryCode, continentCode);
  return currencyByCode(currencyRegion);
};

/**
 * Formats a price with thousands separators and a currency symbol.
 * @param amount - The number or string to format (e.g., 1234.56 or "1234.56").
 * @param symbol - Currency symbol to prepend (default: "$").
 * @returns A string with the formatted price (e.g., "$1,234.56").
 */
export function formatPrice(amount: number | string, symbol = "$"): string {
  const numericAmount =
    typeof amount === "string" ? parseFloat(amount) : amount;

  // Handle invalid numbers
  if (isNaN(numericAmount)) {
    return `${symbol}0.00`;
  }

  // Format with commas and 2 decimal places
  const formattedNumber = numericAmount.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return `${symbol}${formattedNumber}`;
}

/**
 * Return the future date (in ms) used for trial expiration.
 */
export const calculatePlanTrialExpirationDate = (
  plan: Plan,
  hasReferral?: boolean,
  coupon?: Coupon | null
): number | Date => {
  let { trialIntervalLength, trialIntervalUnit } = plan;
  const today = new Date();

  // if user has referral, they get 14 days
  if (hasReferral) return today.setDate(today.getDate() + 2 * 7);

  // if coupon overrides the trial interval
  if (coupon && coupon.trialInterval && coupon.trialIntervalCount) {
    trialIntervalUnit = coupon.trialInterval + "s";
    trialIntervalLength = coupon.trialIntervalCount;
  }

  if (trialIntervalUnit === "days") {
    return today.setDate(today.getDate() + trialIntervalLength);
  }
  if (trialIntervalUnit === "weeks") {
    return today.setDate(today.getDate() + trialIntervalLength * 7);
  }
  if (trialIntervalUnit === "months") {
    return today.setMonth(today.getMonth() + trialIntervalLength);
  }
  return today;
};

export const fmtTrialExpirationDate = (
  plan?: Plan,
  hasReferral?: boolean,
  coupon?: Coupon | null
): string => {
  if (!plan) return "";
  const expirationDate = calculatePlanTrialExpirationDate(
    plan,
    hasReferral,
    coupon
  );
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(expirationDate);
};

export const fmtSubscriptionBillingDate = (
  subscription?: Subscription
): string => {
  if (!subscription) return "";
  const billingDate = new Date(subscription.currentPeriodEndsAt);
  return new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(billingDate);
};

export const fmtSubscriptionBillingDateShort = (
  subscription?: Subscription
): string => {
  if (!subscription) return "";
  const billingDate = new Date(subscription.currentPeriodEndsAt);
  return new Intl.DateTimeFormat("en-US", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
  }).format(billingDate);
};

export const trackAfterSubscribe = (
  accountType: AccountType,
  track: (event: SegmentEventType, properties: any) => void,
  subscriptionExpired: boolean,
  paymentToken?: string,
  plan?: Plan,
  zipCode?: string | null,
  signupVersion?: "variant" | "baseline"
): void => {
  if (!plan) return;
  const { currency, amountInCents } = plan;
  const amtKey = currency?.toUpperCase() || "USD";
  const amount = (amountInCents[amtKey] || 0) / 100;

  const trackData = {
    signupVersion,
    accountType,
    orderId: paymentToken,
    platform: "Web",
    affiliation: "Web",
    subtotal: amount,
    total: amount,
    revenue: amount,
    shipping: 0,
    currency: plan.currency,
    zipCode: zipCode ?? "",
    products: {
      id: plan.id,
      name: plan.name,
      price: amount,
      quantity: 1,
    },
  };
  if (!subscriptionExpired) {
    track("Subscribed", trackData);
    track("Order Completed", trackData);
  }
};

export const trackSubscrbeCta = (
  track: (event: SegmentEventType, properties: any) => void,
  step: "ath-payment" | "org-payment",
  expired: boolean
): void => {
  track("CTA Tapped", {
    name:
      step === "ath-payment" && expired
        ? "Start Your Membership"
        : step === "ath-payment"
        ? "Start Your Trial"
        : "Start Your Membership Organization",
    source: "Payment Page",
  });
};

export const orgPlanPrice = (billingCount: number, plan: Plan): string => {
  if (!plan || !plan.tiers) return "$0.00";

  if (billingCount <= 0) {
    return "0.00";
  }

  const getFlatAmountInCents = (tier: Tier): number => {
    if (typeof tier.flatAmount === "number") return tier.flatAmount;
    if (tier.flatAmountDecimal) return parseInt(tier.flatAmountDecimal, 10);
    return 0;
  };
  const getUnitAmountInCents = (tier: Tier): number => {
    if (typeof tier.unitAmount === "number") return tier.unitAmount;
    if (tier.unitAmountDecimal) return parseInt(tier.unitAmountDecimal, 10);
    return 0;
  };

  const isVolumePricing = plan.tiers.every(
    (t) => !t.unitAmount && !t.unitAmountDecimal
  );

  if (isVolumePricing) {
    for (const tier of plan.tiers) {
      const upTo = tier.upTo ?? Infinity;
      const flat = getFlatAmountInCents(tier);
      if (billingCount <= upTo) {
        return twoFixed(flat / 100);
      }
    }
    return "0.00";
  }

  let totalCostInCents = 0;
  let remainingSeats = billingCount;
  let previousUpTo = 0;

  for (const tier of plan.tiers) {
    if (remainingSeats <= 0) break;

    const upTo = tier.upTo ?? Infinity;
    const seatsInThisTier = Math.min(remainingSeats, upTo - previousUpTo);

    if (seatsInThisTier > 0) {
      const flat = getFlatAmountInCents(tier);
      const unit = getUnitAmountInCents(tier);

      if (flat > 0) {
        totalCostInCents += flat;
      } else if (unit > 0) {
        totalCostInCents += seatsInThisTier * unit;
      }
      remainingSeats -= seatsInThisTier;
    }
    previousUpTo = upTo;
  }

  return twoFixed(totalCostInCents / 100);
};

function twoFixed(val: number, decimals = 2): string {
  // Floor to two decimals, then format with 2 decimals.
  const factor = Math.pow(10, decimals);
  const floored = Math.floor(val * factor) / factor;
  return floored.toFixed(decimals);
}

export function firstTierMonthlyCost(orgPlan: Plan): number {
  return parseInt(orgPlan?.tiers?.[0]?.flatAmountDecimal || "1", 10) / 100;
}

export const monthlyPlanInfoOrg = (plans: Plan[] | null): PlanInfoOrg => {
  const monthlyPlan = Array.isArray(plans)
    ? (plans.find((p) => p.code === "org-account-plan") as Plan)
    : (plans as unknown as Plan);

  const monthlyPlanCurrencyCode = monthlyPlan?.currency?.toUpperCase();
  const monthlyPlanCurrency = currencyByCode(
    monthlyPlanCurrencyCode as TCurrencyKey
  );

  const monthlyPlanAmt =
    (monthlyPlanCurrencyCode &&
      monthlyPlan?.amountInCents[monthlyPlanCurrencyCode] / 100) ||
    0;

  return {
    type: "monthly",
    currency: monthlyPlanCurrency.currency,
    currencyCode: monthlyPlanCurrency.currencyKey,
    plan: monthlyPlan,
    monthlyAmount: monthlyPlanAmt,
    yearlyAmount: monthlyPlanAmt * 12,
    monthlyAmountStr: `${monthlyPlanCurrency.currency}${monthlyPlanAmt.toFixed(
      2
    )}`,
    yearlyAmountStr: `${monthlyPlanCurrency.currency}${(
      monthlyPlanAmt * 12
    ).toFixed(2)}`,
  };
};

export const yearlyPlanInfoOrg = (plans: Plan[] | null): PlanInfoOrg => {
  const yearlyPlan =
    plans && Array.isArray(plans)
      ? (plans.find((p) => p.code === "org-account-yearly") as Plan)
      : (plans as unknown as Plan);

  const yearlyPlanCurrencyCode = yearlyPlan?.currency?.toUpperCase();
  const yearlyPlanCurrency = currencyByCode(
    yearlyPlanCurrencyCode as TCurrencyKey
  );
  const yearlyPlanAmt =
    (yearlyPlanCurrencyCode &&
      yearlyPlan?.amountInCents[yearlyPlanCurrencyCode] / 100) ||
    0;

  const yearlyPerMonth = yearlyPlanAmt ? yearlyPlanAmt / 12 : 0;

  return {
    type: "yearly",
    currency: yearlyPlanCurrency.currency,
    currencyCode: yearlyPlanCurrency.currencyKey,
    plan: yearlyPlan,
    monthlyAmount: yearlyPerMonth,
    yearlyAmount: yearlyPlanAmt,
    monthlyAmountStr: `${yearlyPlanCurrency.currency}${yearlyPerMonth.toFixed(
      2
    )}`,
    yearlyAmountStr: `${yearlyPlanCurrency.currency}${yearlyPlanAmt.toFixed(
      2
    )}`,
  };
};

type TieredPricing = {
  seatsInThisTier: number;
  price: number;
  perUserPrice: number;
  tierLabel: string;
}[];

export const calculateTieredPricing = (
  plan: Plan,
  additionalSeats: number,
  baseTier: Tier | null | undefined,
  orgSizeLimit: number
): TieredPricing => {
  let remainingSeats = additionalSeats;
  const tieredPricing: TieredPricing = [];

  let previousUpTo = baseTier?.upTo || 0;

  for (const tier of plan?.tiers || []) {
    if (tier.unitAmount) {
      const minSeats = previousUpTo + 1;
      const maxSeats = tier.upTo ?? orgSizeLimit;
      const seatsInThisTier = Math.min(remainingSeats, maxSeats - minSeats + 1);

      if (seatsInThisTier > 0) {
        tieredPricing.push({
          seatsInThisTier,
          price: seatsInThisTier * (tier.unitAmount / 100),
          perUserPrice: tier.unitAmount / 100,
          tierLabel: `${minSeats}-${maxSeats} Member Tier`,
        });
        remainingSeats -= seatsInThisTier;
      }

      previousUpTo = maxSeats;
      if (remainingSeats <= 0) break;
    }
  }

  return tieredPricing;
};

export const calculateAnnualSavings = (
  teamSize: number,
  monthlyPlan: Plan,
  annualPlan: Plan
): number => {
  const monthlyPrice = orgPlanPrice(teamSize, monthlyPlan);
  if (!monthlyPrice) return 0;

  const monthlyCost = parseFloat(monthlyPrice) * 12;
  const annualCost = parseFloat(orgPlanPrice(teamSize, annualPlan));
  return monthlyCost - annualCost;
};

const getPlanInfo = (
  planType: "monthly" | "yearly" | "sixMonth",
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps,
  intervalUnit: string,
  intervalLength: number,
  isValidCoupon = false,
  couponAttrs: Coupon | null = null,
  monthlyPlanAmt?: number
): PlanInfo => {
  const currencyCode =
    currentPlan?.currency?.toUpperCase() ||
    getCurrencyRegion(reqProps.country, reqProps.continent);

  let planCode: TPlanCode;
  if (planType === "monthly") {
    planCode = getDefaultMonthlyPlanCode(reqProps.country, reqProps.continent);
  } else if (planType === "yearly") {
    planCode = getDefaultYearlyPlanCode(reqProps.country, reqProps.continent);
  } else {
    planCode = getDefaultSixMonthPlanCode(reqProps.country, reqProps.continent);
  }

  let plan: Plan | null = Array.isArray(plans)
    ? plans.find(
        (p) => p.code === planCode && p.currency?.toUpperCase() === currencyCode
      ) || null
    : null;

  if (!plan && currentPlan) {
    if (
      currentPlan.planIntervalUnit === intervalUnit &&
      currentPlan.planIntervalLength === intervalLength
    ) {
      plan = currentPlan;
    } else {
      plan =
        plans?.find(
          (p) =>
            p.planIntervalUnit === intervalUnit &&
            p.planIntervalLength === intervalLength &&
            p.currency?.toUpperCase() === currencyCode
        ) || null;
    }
  }

  if (!plan) {
    plan = plans?.find(
      (p) => p.code === planCode && p.currency?.toUpperCase() === currencyCode
    ) as Plan;
  }

  // Fallback to USD plan if no plan is found
  if (!plan) {
    const usdPlanCode =
      planType === "monthly"
        ? "individual-monthly-usd"
        : planType === "yearly"
        ? "individual-yearly-usd"
        : "individual-sixMonth-usd";
    plan = plans?.find((p) => p.code === usdPlanCode) as Plan;
  }

  const planCurrency = currencyByCode(currencyCode as TCurrencyKey);
  const planAmt = (plan?.amountInCents?.[currencyCode] || 0) / 100;

  let promotionalPrice = planAmt;
  if (isValidCoupon && couponAttrs?.planType === planType) {
    if (couponAttrs.amountOff) {
      promotionalPrice = Math.ceil(planAmt - couponAttrs.amountOff / 100);
    } else if (couponAttrs.percentOff) {
      promotionalPrice = Math.ceil(
        planAmt - planAmt * (couponAttrs.percentOff / 100)
      );
    }
  }

  const monthlyAmount =
    planType === "yearly"
      ? parseFloat(twoFixed(planAmt / 12))
      : planType === "sixMonth"
      ? parseFloat(twoFixed(planAmt / 6))
      : parseFloat(twoFixed(planAmt));
  const yearlyAmount =
    planType === "yearly"
      ? parseFloat(twoFixed(planAmt))
      : planType === "sixMonth"
      ? parseFloat(twoFixed(planAmt))
      : parseFloat(twoFixed(planAmt * 12));

  let yearlyDiscount = "";

  if (planType === "yearly" && isValidCoupon && (monthlyPlanAmt ?? 0) > 0) {
    const monthlyAnnualCost = monthlyPlanAmt! * 12;
    const difference = monthlyAnnualCost - promotionalPrice;
    if (difference > 0) {
      const discountPct = ((difference / monthlyAnnualCost) * 100).toFixed(0);
      if (discountPct !== "0") {
        yearlyDiscount = discountPct;
      }
    }
  }

  const monthlyPromotionalPrice =
    planType === "yearly"
      ? promotionalPrice / 12
      : promotionalPrice / intervalLength;

  return {
    planType,
    currency: planCurrency.currency,
    currencyCode: planCurrency.currencyKey,
    plan,
    monthlyAmount,
    yearlyAmount,
    monthlyAmountStr: `${planCurrency.currency}${twoFixed(monthlyAmount)}`,
    yearlyAmountStr: `${planCurrency.currency}${yearlyAmount.toFixed(2)}`,
    yearlyDiscount,
    promotionalPrice,
    promotionalPriceStr: `${planCurrency.currency}${promotionalPrice.toFixed(
      2
    )}`,
    monthlyPromotionalPrice,
    monthlyPromotionalPriceStr: `${planCurrency.currency}${twoFixed(
      monthlyPromotionalPrice
    )}`,
    coupon: couponAttrs,
    amount: planAmt,
  };
};

export const monthlyPlanInfo = (
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps
): PlanInfo => {
  return getPlanInfo("monthly", currentPlan, plans, reqProps, "month", 1);
};

export const yearlyPlanInfo = (
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps,
  monthlyPlanAmt: number,
  isValidCoupon: boolean,
  couponAttrs: Coupon | null
): PlanInfo => {
  return getPlanInfo(
    "yearly",
    currentPlan,
    plans,
    reqProps,
    "year",
    1,
    isValidCoupon,
    couponAttrs,
    monthlyPlanAmt
  );
};

export const sixMonthPlanInfo = (
  currentPlan: Plan | null,
  plans: Plan[] | null,
  reqProps: ReqProps,
  isValidCoupon: boolean,
  couponAttrs: Coupon | null
): PlanInfo => {
  return getPlanInfo(
    "sixMonth",
    currentPlan,
    plans,
    reqProps,
    "month",
    6,
    isValidCoupon,
    couponAttrs
  );
};
